"use client"; // Error components must be Client Components
import { Button } from "@/components/Button";
import ErrorPage from "@/components/ErrorPage";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <ErrorPage error={error.digest ?? error.message}>
      <Button type="button" onClick={reset} size="lg">
        Intentar de nuevo
      </Button>
    </ErrorPage>
  );
}
