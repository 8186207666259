import React from "react";

interface Props {
  title?: string;
  error: string;
  children?: React.ReactNode;
}

const ErrorPage = ({ title = "¡Algo salió mal!", error, children }: Props) => {
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <h2 className="text-3xl text-center">{title}</h2>
      <p className="text-center">{error}</p>
      <div className="mt-4">{children}</div>
    </div>
  );
};

export default ErrorPage;
